import React from 'react'

export default function Completed() {

    return(

        <div className = "completed">

            
        </div>
        
    )
}