import React from 'react'

export default function OrderHistory({ orders }) {    

    return(

        <div className="orderhistory">
            History
        </div>
        
    )
}